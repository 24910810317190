import React, {useState} from 'react';
import { useHistory } from "react-router-dom";
import {Button, Icon, message, Skeleton, Upload, DatePicker, notification} from "antd";
import logo from "../../../assets/images/dashboard/avatar.svg";
import {getAuthUser, updateAvatar, updateUser} from "../../../APIsHandler/ApiController";
import {connect} from "react-redux";
import moment from "moment";
import polygon from "../../../assets/images/dashboard/polygon.svg";


const UserProfile = (props) => {
    const history = useHistory();

    const [first_name, setFirstName] = useState(props.user.data.firstname);
    const [last_name, setLastName] = useState(props.user.data.lastname);
    const [date_of_birth, setDOB] = useState((props.user.data.hasOwnProperty("user_profile") && props.user.data.user_profile !== null &&
        props.user.data.user_profile.date_of_birth !== null ) ? moment(props.user.data.user_profile.date_of_birth).format("YYYY-MM-DD") :
        moment(new Date()).subtract("78", "years").format("YYYY-MM-DD"));
    const [next_of_kin, setNextOfKin] = useState((props.user.data.hasOwnProperty("user_profile") && props.user.data.user_profile !== null &&
        props.user.data.user_profile.next_of_kin !== null) ? props.user.data.user_profile.next_of_kin : "");
    const [phone, setPhone] = useState((props.user.data.hasOwnProperty("user_profile") && props.user.data.user_profile !== null && props.user.data.user_profile.phone_number !== null)
        ? props.user.data.user_profile.phone_number : "");
    const [bvn, setBVN] = useState((props.user.data.hasOwnProperty("user_profile") && props.user.data.user_profile !== null && props.user.data.user_profile.bvn !== null)
    ? props.user.data.user_profile.bvn : "");
    const [loading, setLoading] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [avatar, setAvatar] = useState((props.user.data.hasOwnProperty("user_profile") && props.user.data.user_profile !== null && props.user.data.user_profile.avatar !== null)
        ? props.user.data.user_profile.avatar : logo);


    const openNotification = (type, title, description) => {
        notification[type]({
            message: `${title}`,
            description: `${description}`,
        });
    };

   const updateDate = (date, dateString) => {
       setDOB(dateString);
    };

    /*
    * Update user profile*/
    const updateUserProfile = (event) =>{
        event.preventDefault();
        
        if(bvn.length < 1) {
            openNotification('error', 'Error', 'BVN is required');
            return;
        }
        
        if(bvn.length !== 11) {
            openNotification('error', 'Error', 'BVN must be 11 digits');
            return;
        }

        setLoading(true);

        const formData = new FormData();
        fileList.forEach( file => {
            formData.append('avatar', file);
        });
        if (first_name) {
            formData.append('firstname', first_name);
        }
        if (last_name) {
            formData.append('lastname', last_name);
        }
        if(phone) {
            formData.append('phone_number', phone);
        }
        if (next_of_kin) {
            formData.append('next_of_kin',  next_of_kin);
        }
        if(bvn) {
            formData.append('bvn', bvn);
        }

        if(date_of_birth && date_of_birth !== 'Invalid date'){
            formData.append('date_of_birth', moment(date_of_birth).format("YYYY-MM-DD"));
        }

        updateUser(formData).then( res => {
            const {data} = res.data;
            setLoading(false);
            props.getAuthUser();

            const queryString = new URLSearchParams(window.location.search);
            const redirect = queryString.get('redirect');

            openNotification("success", "Profile Updated", "Your profile was updated successfully.")

            if(redirect === "apply"){
                setTimeout(() => {
                    history.push("/dashboard/repayment-options");
                }, 1000);
            }
        }).catch( err => {
            setLoading(false);
            openNotification("error", "Profile update failed", `Unable to update profile. ${ (err.data !== undefined) ? err.data.message: ''}`)
        })
    };

    const isValidDate = (d) => {
        let date  = moment(d);
        console.log('IsValid Date', date.isValid());
        return date.isValid();
    };

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const onPixChange = (info) => {
        const status = info.file.status;
        if (status !== 'uploading') {
            console.log('Uploading');
            setLoading(true);
        }
        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, imageUrl => {
                console.log('ImageLink', imageUrl);
                setAvatar(imageUrl);
                setLoading(false);
            });

            localStorage.setItem("sfs_avatar"+props.user.data.id, JSON.stringify(info.file.response.data.avatar));
            //console the response
            props.getAuthUser()
        } else if (status === 'error') {
            console.log('Error: ', info.file);
            message.error(`${info.file.name} file upload failed.`);
        }
    };

    /**
     * handle the manual upload of the id card check
     * */
    const beforeUpload = file => {
        const isJpgOrPng = file.type === 'image/png' || file.type === 'image/jpeg';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }

        setFileList([...fileList, file]);
        console.log('Filelist', fileList);
        return isJpgOrPng && isLt2M;
    };

    const imageUpload =  (info) => {
        console.log('Inside custom value');
        console.log(info);
        let formData = new FormData();
        formData.append('avatar', info.file);
        updateAvatar(formData).then( res => {
            info.onSuccess(res.data, info.file);
        }).catch( err => {
            console.log(err);
            info.onError('File upload issue', info.file);
        });
    };
    const upload = {
        name: 'avatar',
        beforeUpload: beforeUpload,
        onChange: onPixChange,
        customRequest: imageUpload
    };

    /*
    * disable date*/
    const disabledEndDate = current => {
        const customDate = moment(new Date()).subtract(18, "years").format("YYYY-MM-DD");
        return current && current > moment(customDate, "YYYY-MM-DD");
    };

    const {user} = props;

    return(
        <div>
            <Skeleton loading={user.fetching} active>
                    <div className="row">
                        <div className="col-md-10">
                            <h1 className="z-dashboard-label">
                                Settings
                            </h1>
                            <div className="z-flex-start mt-3">
                                <Icon type="user" className="z-label-icon"/>
                                <span className="z-label-text">Profile Settings</span>
                            </div>
                            <div className="mt-4">
                                <div className="z-testimonial-avatar z-profile-avatar z-mb-1">
                                    <img src={avatar} alt="avatar"/>
                                    <Upload {...upload} className="z-upload-flex"
                                    >
                                        <div className="profile-icon">
                                            <img src={polygon} alt="Polygon" className="img-fluid" style={{width: "10px", height: "auto"}} />
                                        </div>
                                    </Upload>
                                </div>
                                <div className="z-mt-10">
                                <div className="row">
                                    <div className="col-md-4 mb-4">
                                        <p className="z-profile-label mb-2">First name</p>
                                        <input size="large" placeholder="first name" value={first_name} name="first_name"
                                               onChange={e => setFirstName(e.target.value)}
                                               className="form-control profile-form"/>
                                    </div>
                                    <div className="col-md-4 mb-4">
                                        <p className="z-profile-label mb-2">Last name</p>
                                        <input size="large" placeholder="last name" value={last_name} name="last_name" onChange={e => {setLastName(e.target.value)}}
                                               className="form-control profile-form"/>
                                    </div>
                                    <div className="col-md-4 mb-4">
                                        <p className="z-profile-label mb-2">Date of birth</p>
                                        <DatePicker
                                            className="profile-form"
                                            onChange={updateDate}
                                            defaultValue={!isValidDate(date_of_birth) ? null : moment(date_of_birth)}
                                            format='YYYY-MM-DD'
                                            disabledDate={disabledEndDate}
                                            allowClear={false}
                                        />
                                    </div>
                                    <div className="col-md-4 mb-4">
                                        <p className="z-profile-label mb-2">Phone Number</p>
                                        <input size="large" placeholder="Phone Number" value={phone} name="phone" onChange={e => {setPhone(e.target.value)}}
                                               className="form-control profile-form"/>
                                    </div>
                                    <div className="col-md-4 mb-4">
                                        <p className="z-profile-label mb-2">BVN <span>*</span></p>
                                        <input size="large" placeholder="bvn" value={bvn} name="bvn" onChange={e => {setBVN(e.target.value)}}
                                               className="form-control profile-form"/>
                                    </div>
                                    <div className="col-md-4 mb-4">
                                        <p className="z-profile-label mb-2">Next of kin</p>
                                        <input size="large" placeholder="next of kin" value={next_of_kin} name="next_of_kin" onChange={e => {setNextOfKin(e.target.value)}}
                                               className="form-control profile-form"/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-8">
                                        <Button className="z-landing-btn z-section-btn z-profile-button mr-3 mb-3" shape="round"
                                                size="large" loading={loading} onClick={event => {updateUserProfile(event)}}>Update Profile</Button>
                                        <Button className="z-landing-btn z-section-btn z-profile-button z-button-plain2 mr-3 mb-3" shape="round" size="large">Cancel</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </Skeleton>
        </div>
        )
};

/**
 * function to map the state to props
 * */
function mapStateToProps(state) {
    return {
        user: state.user,
    }
}

export default connect(mapStateToProps, {getAuthUser})(UserProfile);