import React, { useEffect, useState } from "react";
import { Button, Icon, message, Row, Skeleton, Popconfirm } from "antd";
import { useHistory, useParams } from "react-router-dom";
import {
    getUserCards,
    initializeNewCardV2,
} from "../../../APIsHandler/ApiController";
import card from "../../../assets/images/undraw_credit_card_df1m (1).svg";
import verve from "../../../assets/images/verve.png";
import visa from "../../../assets/images/visa.jpg";
import mastercard from "../../../assets/images/mastercard_vrt_pos_92px_2x.png";
import cardchip from "../../../assets/images/sim-card-chip.png";

export default function Card() {
    const history = useHistory();
    const { defaultCard } = useParams();

    const [cards, setCards] = useState([]);
    const [loading, setLoading] = useState(true);
    const [tab, setTab] = useState(0);
    const [currentCard, setCurrentCard] = useState(0);
    const [isInitializing, setIsInitializing] = useState(false);

    useEffect(() => {
        getUserCards((status, data) => {
            setLoading(false);
            if (status) {
                setCards(data);
                setDefaultCard(data);
            } else {
                message.error(
                    "Unable to retrieve debit cards at the moment.",
                    3
                );
            }
        });
    }, []);

    const setDefaultCard = (cards) => {
        if (!defaultCard) return;

        cards.forEach((card, i) => {
            card.id === defaultCard && setCurrentCard(i);
        });
    };

    const initializeNewCardAproval = () => {
        setIsInitializing(true);
        initializeNewCardV2(
            `${window.location.origin}/dashboard/handle-authorization`
        )
            .then((response) => {
                window.location.replace(response.data.data.authorization_url);
            })
            .catch(() => {
                setIsInitializing(false);
                message.error("Unable to set debit cards at the moment.", 3);
            });
    };

    const navLeft = () => {
        if (currentCard > 0) {
            setCurrentCard(currentCard - 1);
        }
    };

    const navRight = () => {
        if (currentCard < cards.length - 1) {
            setCurrentCard(currentCard + 1);
        }
    };

    const handleProceed = () => {
        const currentCardAuthId = cards[currentCard].id;
        const param = new URLSearchParams({
            repayment_option: "card",
            id: currentCardAuthId
        });
        history.push(`/dashboard/apply/?${param.toString()}`);
    };

    return (
        <Skeleton active loading={loading}>
            {cards.length > 0 && tab === 0 && (
                <Row>
                    <p className="card-text-title z-no-mb z-textp">
                        Please, select a card
                    </p>
                    <Icon
                        type="left"
                        className={
                            currentCard <= 0
                                ? "z-card-arrowl z-disabled"
                                : "z-card-arrowl"
                        }
                        onClick={navLeft}
                    />
                    <div className="z-card-debit">
                        <Icon type="check-circle" className="check-circle" />
                        <div className="z-chip">
                            <img src={cardchip} alt="cardchip" />
                        </div>
                        <div>
                            <p className="z-apply-label-small z-no-mb z-modal-parag-small z-modal-parag-small2">
                                ****
                                &nbsp;&nbsp;&nbsp;&nbsp;****&nbsp;&nbsp;&nbsp;&nbsp;
                                **** &nbsp;&nbsp;&nbsp;&nbsp;
                                {cards[currentCard].last4}
                            </p>
                        </div>

                        <div className="z-chip z-chip2">
                            <img
                                src={
                                    cards[currentCard].card_type.includes(
                                        "verve"
                                    )
                                        ? verve
                                        : cards[currentCard].card_type.includes(
                                              "visa"
                                          )
                                        ? visa
                                        : cards[currentCard].card_type.includes(
                                              "master"
                                          )
                                        ? mastercard
                                        : ""
                                }
                                alt="cardchip"
                            />
                        </div>
                    </div>
                    <p className="z-navp">
                        {currentCard + 1}/{cards.length}
                    </p>
                    <Icon
                        type="right"
                        className={
                            currentCard >= cards.length - 1
                                ? "z-card-arrowR z-disabled"
                                : "z-card-arrowR"
                        }
                        onClick={navRight}
                    />
                    {cards.length > 0 && (
                        <div
                            className="z-flex-center"
                            onClick={() => setTab(1)}
                        >
                            <p className="z-apply-label-small z-no-mb z-modal-parag-small">
                                Use a new card
                            </p>
                        </div>
                    )}
                </Row>
            )}

            {(cards.length < 1 || tab === 1) && (
                <Row>
                    <div className="z-icon-modal">
                        <img src={card} alt="card" />
                    </div>
                    <div>
                        <p className="z-apply-label-small z-modal-parag">
                            In order to apply for a loan, you need to have a
                            card on the platform. A sum of 50 naira will be
                            deducted which will be deposited in your wallet
                            after a successful debit.
                        </p>
                        <p className="z-apply-label-small z-modal-parag">
                            Note that the card <strong>MUST</strong> be linked
                            to your salary account
                        </p>
                    </div>
                    {cards.length > 0 && (
                        <div
                            className="z-flex-center"
                            onClick={() => setTab(0)}
                        >
                            {/*<Checkbox className="z-ml-12 z-checkbox" onChange={e => {this.handleRadio(e)}}/>*/}
                            <p className="z-apply-label-small z-no-mb z-modal-parag-small">
                                Use an existing card
                            </p>
                        </div>
                    )}
                </Row>
            )}
            <div className="card-btn-group">
                {cards.length > 0 && tab === 0 ? (
                    <Popconfirm
                        title="Do you want to continue with this card?"
                        onConfirm={handleProceed}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            className="z-landing-btn z-section-btn z-profile-button z-loan-btn"
                            shape="round"
                            size="large"
                            key="1"
                        >
                            Proceed{" "}
                        </Button>
                    </Popconfirm>
                ) : cards.length < 1 || tab === 1 ? (
                    <Button
                        className="z-landing-btn z-section-btn z-profile-button z-loan-btn"
                        shape="round"
                        size="large"
                        onClick={initializeNewCardAproval}
                        key="1"
                        loading={isInitializing}
                    >
                        Add Card{" "}
                    </Button>
                ) : (
                    <></>
                )}
            </div>
        </Skeleton>
    );
}
