import React, { useEffect, useState } from "react";
import { Button, Modal, Row, message, Skeleton } from "antd";
import { getPaybackSummary } from "../../../APIsHandler/ApiController";
import PaybackSummary from "./PaybackSummary";
import { formatCurrency, naira } from "../../globalComponents/globalFunctions";
import UserLoanMainDetails from "./UserLoanMainDetails";

export default function LoanPaybackSummary({
    amountRequested,
    loanpackageId,
    closeModal,
    showModal,
    onApprove,
    isApproving,
    packageName,
    amount,
    currentSalary,
    companyLevel,
    guarantorEmail,
}) {
    const [isLoading, setIsLoading] = useState(true);
    const [allSummary, setAllSummary] = useState([]);
    const [tab, setTab] = useState("main");

    useEffect(() => {
        // when the modal opens or closes it should default back to the main tab
        setTab("main");
    }, [showModal]);

    useEffect(() => {
        if (showModal)
            // getPaybackSummary(1, 20000)
            getPaybackSummary(loanpackageId, amountRequested)
                .then((response) => {
                    const data = response.data.data;
                    setAllSummary(data);
                    setIsLoading(false);
                })
                .catch((error) => {
                    setIsLoading(false);
                    message.error(
                        `Unable to fetch loan payback summay. ${
                            error.data !== undefined ? error.data.message : ""
                        }. Please try again.`,
                        3
                    );
                });
    }, [showModal]);

    function changeTab(tabId) {
        setTab(tabId);
    }

    return (
        <Modal
            className="z-custom z-custom2 z-custom3 "
            visible={showModal}
            onCancel={closeModal}
            title={"w"}
            footer={[
                <Button
                    className="z-landing-btn z-section-btn z-profile-button z-skeleton-btn z-flex-row z-btn-company z-border-active"
                    shape="round"
                    size="large"
                    onClick={closeModal}
                    key={1}
                >
                    Cancel
                </Button>,
                <Button
                    className="z-landing-btn z-section-btn z-profile-button z-skeleton-btn z-skeleton-bg z-btn-company2-active"
                    shape="round"
                    size="large"
                    onClick={onApprove}
                    loading={isApproving}
                    key={2}
                >
                    Apply
                </Button>,
            ]}
        >
            <div className="z-flex-space z-modal-label-div">
                <div>
                    <p className="z-loan-header">Application Summary</p>
                </div>
                <div>
                    <p className="z-loan-header z-loan-header-small">
                        {packageName}
                    </p>
                </div>
            </div>
            <Row>
                <div className="z-flex-space z-gr-p z-mt-3 z-mb-3">
                    <div className="z-aside-tab z-flex-even z-ml-5">
                        <div className="z-flex-column-center">
                            {" "}
                            <p className="z-loan-p3">Loan amount</p>
                            <p className="z-loan-p">
                                {amount} &nbsp;&nbsp;
                                <span className="z-loan-p3 z-font-xs">
                                    {naira}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
                <Skeleton loading={isLoading} active>
                    <div className="z-flex-space z-guarantor-padding2 z-mb-3">
                        <div
                            className={
                                tab === "main"
                                    ? "z-width-100 z-flex-row z-guarantor-active z-cursor-poiner"
                                    : "z-cursor-poiner z-width-100 z-flex-row"
                            }
                            onClick={() => changeTab("main")}
                        >
                            <p className="z-guarantor-tab z-ml-8 z-notification-active">
                                Main
                            </p>
                        </div>
                        <div
                            className={
                                tab === "summary"
                                    ? "z-width-100 z-flex-row z-guarantor-active z-cursor-poiner"
                                    : "z-cursor-poiner z-width-100 z-flex-row"
                            }
                            onClick={() => changeTab("summary")}
                        >
                            <p className="z-guarantor-tab z-ml-8 z-notification-active">
                                Payback Schedule
                            </p>
                        </div>
                    </div>

                    {tab === "main" ? (
                        <UserLoanMainDetails
                            currentSalary={currentSalary}
                            companyLevel={companyLevel}
                            guarantorEmail={guarantorEmail}
                        />
                    ) : (
                        <PaybackSummary allSummary={allSummary} />
                    )}
                </Skeleton>
            </Row>
        </Modal>
    );
}
