import React, { useEffect } from "react";
import { Skeleton, message } from "antd";
import { authorizeNewCardV2 } from "../../../APIsHandler/ApiController";
import { useHistory } from "react-router-dom";

export default function HandleCardAuthorization() {
    const history = useHistory();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const reference = urlParams.get("reference");

        authorizeNewCardV2(reference)
            .then(({ data }) => {
                history.push(`/dashboard/repayment-options/${data.data}`);
            })
            .catch((error) => {
                message.error(
                    "Failed to authorize card. " + error.data !== undefined
                        ? error.data.message
                        : "",
                    3
                );

                setTimeout(() => {
                    history.push("/dashboard/repayment-options");
                }, 500);
            });
    }, []);

    return <Skeleton active loading={true}></Skeleton>;
}
